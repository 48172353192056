import React from 'react';
import { Container, Grid, Header, Image, Divider } from 'semantic-ui-react';
import '../App.css'
import sample from '../sample_home_wireframe.png'
import sample2 from '../sample_home_wireframe_2.png'
import samplefilter from '../sample_filter_wireframe.png'
import google_play_button from '../google-play.png'
import app_store_button from '../app-store.png'

export default function Home() {

    return (
        <Container >
            <Grid centered textAlign="center" stackable>
                <Grid.Row >
                    <Grid.Column textAlign="center">
                        <Header 
                            className="htext"
                            content="Just Dogs" 
                            as="h1"
                            style={{
                                'font-size':'124px',
                                'font-family': 'Airstream',
                                'letter-spacing': '5px',
                                //'margin-top': '200px',
                                'animation-name': 'fadeIn',
                                'animation-duration': '2s'
                            }}/>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column textAlign="center">
                        <Header 
                            content="Thats it. It's really just dogs." 
                            as="h1"
                            style={{
                                'font-size':'35px',
                                'font-family': 'Airstream',
                                'letter-spacing': '2px',
                                'animation-name': 'fadeIn2',
                                'animation-duration': '4s',
                            }}/>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row  verticalAlign="middle" centered>
                    <Grid.Column>
                        <Image src={google_play_button} size="small" style={{
                            // 'height': '50px', 
                            // 'width':'140px',
                            'display':'block',
                            'margin-left': 'auto',
                            'margin-right': 'auto',
                            'animation-name': 'fadeIn2',
                            'animation-duration': '6s'
                        }}/>
                        <Image src={app_store_button} size="small" style={{
                            // 'height': '50px',
                            // 'width':'140px',
                            'display':'block',
                            'margin-left': 'auto',
                            'margin-right': 'auto',
                            'animation-name': 'fadeIn2',
                            'animation-duration': '6s',
                        }}/>
                    </Grid.Column>
                </Grid.Row>
                <Divider style={{ 
                    'margin': '300px 25px 25px 25px'
                }}/>
                <Grid.Row columns={2}>
                <Grid.Column> 
                        {/* <Image src={samplefilter} size="large" /> */}
                        <Header className="htext" as="h1" content="Just Dogs is an app made for looking at pictures of man's best friend. No likes nor retweets, no influencers nor celebrities, just dogs." style={{
                            'font-size':'35px',
                            'font-family': 'Airstream',
                            'letter-spacing': '2px',
                            //'margin-top': '250px',
                            'animation-name': 'fadeIn',
                            'animation-duration': '2s'
                        }}/>
                    </Grid.Column>
                    <Grid.Column> 
                        <Image src={sample} size="large" />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2}>
                        <Grid.Column> 
                            <Image src={sample2} size="large" />
                        </Grid.Column>
                        <Grid.Column>
                        <Header className="htext" as="h1" content="Every dog on Just Dogs has been shared by a fellow member of the Just Dogs community. Feel free to share your pup or simply scroll through the home page to see the beautiful variety of puppies our community has shared." style={{
                            'font-size':'35px',
                            'font-family': 'Airstream',
                            'letter-spacing': '2px',
                           // 'margin-top': '250px',
                            'animation-name': 'fadeIn',
                            'animation-duration': '2s'
                        }}/>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2}>
                
                    <Grid.Column>
                        <Header className="htext" as="h1" content="You can also filter dogs by breed.  Ever wonder what a chihuahua mixed with a border collie looks like?" style={{
                            'font-size':'35px',
                            'font-family': 'Airstream',
                            'letter-spacing': '2px',
                            //'margin-top': '250px',
                            'animation-name': 'fadeIn',
                            'animation-duration': '2s'
                        }}/>
                    </Grid.Column>
                    <Grid.Column> 
                        <Image src={samplefilter} size="large" />
                    </Grid.Column>
                </Grid.Row>
                <Divider />
                <Grid.Row>
                    <Grid.Column textAlign="center">
                        <Header content="Just Dogs is coming soon!" as="h1" style={{
                            'font-size':'55px',
                            'font-family': 'Airstream',
                        }} />
                        <Header as="h3" >
                            <a style={{'color': '#dddddd'}} href="mailto:justdogsapp@gmail.com?subject=Notify Me&body=Please let me know when Just Dogs is released!">Sign up to be notified when Just Dogs releases</a>
                        </Header>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <p>View our <strong><a style={{'color':'white'}} href="/privacy">Privacy Policy</a></strong></p>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Container>
    );
}