import React from 'react';
import { Container, Header, List, Divider } from 'semantic-ui-react';
import '../App.css'

export default function Privacy() {

    return (
        <Container text style={{'paddingTop':'30px', 'paddingBottom':'30px', }}>
            <Header size="huge" content="Privacy Policy" className="custom-header" as="h1"/>
            <p>
            Just Dogs values its users' privacy. This Privacy Policy will help you understand how we 
            collect and use personal information from those who download and use our application.
            </p>
            <Divider />
            <Header size="large" content="Collection of Your Information" as="h1" />
            <p>
                We may collect information about you in a variety of ways. The information we may collect 
                via the application depends on the content you use, and includes:
            </p>
            <Header size="small" as="h3" content="Personal Data"/>
            <p>
                We collect and store the following personal information related to your in app profile, which you voluntarily
                give us either upon sign-up or through use of the application
            </p>
            <List bulleted>
                <List.Item>Email address</List.Item>
                <List.Item>Photos uploaded</List.Item>
            </List>
            <Header size="small" as="h3" content="Derivative Data and Uploaded Media" />
            <p>
                Our servers automatically collect information when you access the application such as your native
                actions that are integral to navigating through and using different features of the application.  As such, we may also request access to your device's photo or camera
                roll for us to upload your media to the application. Any media uploaded in this fashion will be collected and stored on our servers.
                If you wish to change our access or permissions, you may do so in your device's settings.
            </p>
            <Divider />
            <Header size="large" content="How We Collect Data" as="h1" />
            <p>We may collect the data detailed above in two ways:</p>
            <List bulleted>
                <List.Item>When you voluntarily provide us with the data, such as signing up or submitting a picture of your dog</List.Item>
                <List.Item>Data collected automatically, such as in app analytics to monitor app performance and user experince</List.Item>
            </List>
            <Divider />
            <Header size="large" content="How We Use Your Personal Information" as="h1" />
            <p>
                We will not use the photographs you provide for any reason other than to share with the Just Dogs community.
                We may use information other than the photographs for the following purposes:
            </p>
            <List bulleted>
                <List.Item>
                    Enable you to maintain an account to track which dogs you have shared and what lists you have made
                </List.Item>
                <List.Item>Provide technical support and maintenance for the application</List.Item>
                <List.Item>Perform statistical analysis about use of the application</List.Item>
            </List>
            <Divider />
            <Header size="large" content="Rentention of Your Data" as="h1" />
            <p>
                We will retain your data for as long as your remain a user. You may submit a request any of your submissions to be 
                removed.
                Upon approval, the photograph(s) will be permanently deleted from our servers.
                You may also request to delete your account. Your personal data will be permanently deleted from our servers.
            </p>
        </Container>
    );
}